var render = function () {
  var _vm$itemDynamic, _vm$itemDynamic2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-header text-center bg-primary"
  }, [_c('h3', [_vm._v("Update Detail Body Item Dynamic")])]), _c('div', {
    staticClass: "card-body"
  }, [_vm.successMessage ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "success",
      "dismissible": "",
      "show": _vm.dismissCountDown
    },
    on: {
      "dismissed": function ($event) {
        _vm.dismissCountDown = 0;
      },
      "dismiss-count-down": _vm.countDownChanged
    }
  }, [_vm._v(" " + _vm._s(_vm.successMessage) + " ")])], 1)], 1) : _vm._e(), _vm.errorMessage ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "dismissible": "",
      "show": _vm.dismissCountDown
    },
    on: {
      "dismissed": function ($event) {
        _vm.dismissCountDown = 0;
      },
      "dismiss-count-down": _vm.countDownChanged
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)], 1) : _vm._e(), _c('h5', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.registerItemAx.name
      }
    }
  }, [_c('span', {
    staticClass: "badge badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-angle-left mr-1"
  }), _vm._v("Back")])])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "label-key col-sm-2"
  }, [_vm._v(" Product Name ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "",
      "disabled": ""
    },
    domProps: {
      "value": (_vm$itemDynamic = _vm.itemDynamic) === null || _vm$itemDynamic === void 0 ? void 0 : _vm$itemDynamic.ProductName
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "label-key col-sm-2"
  }, [_vm._v(" Name Of Printing ")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "",
      "disabled": ""
    },
    domProps: {
      "value": (_vm$itemDynamic2 = _vm.itemDynamic) === null || _vm$itemDynamic2 === void 0 ? void 0 : _vm$itemDynamic2.NameOfPrinting
    }
  })])])]), _c('div', {
    staticClass: "form-group col-md-12"
  }, [_vm._l(_vm.itemDynamic, function (value, key) {
    return _c('div', {
      key: key
    }, [_vm.allowedEdit.includes(key) ? _c('div', {
      staticClass: "form-group row"
    }, [_c('label', {
      staticClass: "label-key col-sm-2"
    }, [_vm._v(_vm._s(key) + " :")]), _c('div', {
      staticClass: "col-sm-6"
    }, [_c('input', {
      staticClass: "form-control",
      attrs: {
        "type": "text",
        "placeholder": ""
      },
      domProps: {
        "value": value
      },
      on: {
        "change": function ($event) {
          return _vm.updateValue($event, key);
        }
      }
    })])]) : _vm._e()]);
  }), _c('div', {
    staticClass: "form-group row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-4"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit",
      "disabled": _vm.isDisable
    },
    on: {
      "click": function ($event) {
        return _vm.editItemAx();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Update ")])])])], 2), _c('div', {
    staticClass: "col-sm-3"
  })])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }