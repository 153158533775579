<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-header text-center bg-primary">
        <h3>Update Detail Body Item Dynamic</h3>
      </div>
      <div class="card-body">
        <b-row v-if="successMessage" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="success" 
              dismissible
              :show="dismissCountDown"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged">
              {{  successMessage }}
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-if="errorMessage" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" 
              dismissible
              :show="dismissCountDown"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged">
              {{  errorMessage }}
            </b-alert>
          </b-col>
        </b-row>
        <h5>
          <router-link
            :to="{
              name: registerItemAx.name,
            }"
          >
            <span class="badge badge-primary"
              ><i class="fa fa-angle-left mr-1"></i>Back</span
            >
          </router-link>
        </h5>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="form-group row">
              <label class="label-key col-sm-2"> Product Name </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  :value="itemDynamic?.ProductName"
                  disabled
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="label-key col-sm-2"> Name Of Printing </label>
              <div class="col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  :value="itemDynamic?.NameOfPrinting"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="form-group col-md-12">
            <div v-for="(value, key) in itemDynamic" :key="key">
              <div class="form-group row" v-if="allowedEdit.includes(key)">
                <label class="label-key col-sm-2">{{ key }} :</label>
                <div class="col-sm-6">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    :value="value"
                    @change="updateValue($event, key)"
                  />
                </div>
              </div>
            </div>

            <div class="form-group row justify-content-center">
              <div class="col-sm-4">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  @click="editItemAx()"
                  :disabled="isDisable"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { registerItemAx } from '../../router/microsoft365';

export default {
  name: 'UpdateRegisterAX',
  data() {
    return {
      registerItemAx,
      itemDynamic: {},
      allowedEdit:[
      "PisNumber",
      "ItemGrouping",
      "ProductOwner",
      "Tax",
      "Tag"
      ],
      dismissSecs: 5,
      dismissCountDown: 5,
    };
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.countDownChanged(5)
      setTimeout(() => {
        this.$router.push('/ax/items/register-item-ax');
      }, 5000);
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.countDownChanged(5)
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Update Item AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created: function() {
    const id = this.$route.params.id;
    this.fetchItemAxById({ id }).then((result) =>
      typeof result?.details === 'object'
        ? (this.itemDynamic = result?.details)
        : (this.itemDynamic = null),
    );
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registerAx.isLoading,
      isError: (state) => state.registerAx.isError,
      successMessage: (state) => state.registerAx.SuccessMessage,
      errorMessage: (state) => state.registerAx.ErrorMessage,
      item: (state) => state.registerAx.item,
    }),
    isDisable() {
      return !this.itemDynamic;
    },
  },
  methods: {
    ...mapActions('registerAx', ['fetchItemAxById', 'updateDetailItemDynamic']),
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    editItemAx() {
      const payload = {
        id: this.$route.params.id,
        details: this.itemDynamic,
      };
      console.log(payload);
      this.updateDetailItemDynamic(payload);
    },

    updateValue(e, key) {
      const dataItem = this.itemDynamic;
      dataItem[key] = e?.target.value;
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
